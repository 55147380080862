import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="text-center text-lg-start">
      <div className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span>Siga o Grupo Mais Valor nas redes sociais :</span>
        </div>

        <div>
          <a
            href="https://www.facebook.com/profile.php?id=61558924524517"
            alt="logo do facebook"
            rel="noreferrer noopener"
            className="me-4 text-reset"
            target="_blank"
          >
            <img src={images["facebook.svg"]} alt="logo do facebook"></img>
          </a>

          <a
            href="https://www.instagram.com/grupomaisvalor/"
            alt="logo do instagram"
            rel="noreferrer noopener"
            className="me-4 text-reset"
            target="_blank"
          >
            <img src={images["instagram.svg"]} alt="logo do instagram"></img>
          </a>

          <a
            href="https://www.linkedin.com/company/grupomaisvalor/"
            alt="logo do linkedin"
            rel="noreferrer noopener"
            className="me-4 text-reset"
            target="_blank"
          >
            <img src={images["linkedin.svg"]} alt="logo do linkedin"></img>
          </a>

          <a
            href="https://web.Whatsapp.com/send?phone=5511958684256"
            alt="logo do whatsapp"
            rel="noreferrer noopener"
            className="me-4 text-reset"
            target="_blank"
          >
            <img src={images["whatsapp.svg"]} alt="logo do whatsapp"></img>
          </a>
        </div>
      </div>

      <div>
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <span className="text-uppercase fw-bold">
                <i className="fas fa-credit-card me-3 "></i>Grupo Mais Valor
              </span>
              <p className="mt-3">
                A Mais Valor é uma das maiores gestoras de negócios,
                especializada em assessoria financeira para pessoas físicas e
                empresas, com agências e representantes distribuídos pelos
                principais estados do Brasil.
              </p>
            </div>

            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <span className="text-uppercase fw-bold ">Produtos</span>
              <p className="mt-3">
                <a className="text-reset" href="https://fgts.grupomaisvalor.com.br/" target="_blank" rel="noopener noreferrer">
                  Antecipação Saque-Aniversário FGTS
                </a>
              </p>
              <p>
                <Link
                  className="text-reset"
                  to="/creditoconsignado"
                  rel="noreferrer noopener"
                  target={"_blank"}
                >
                  Crédito Consignado
                </Link>
              </p>
              <p>
                <Link
                  className="text-reset"
                  to="/creditocomgarantiadeimovel"
                  rel="noreferrer noopener"
                  target={"_blank"}
                >
                  Crédito com Garantia de Imóvel
                </Link>
              </p>

              <p>
                <Link
                  className="text-reset"
                  to="/creditocomgarantiadeveiculo"
                  rel="noreferrer noopener"
                  target={"_blank"}
                >
                  Crédito com Garantia de Veículo
                </Link>
              </p>
              <p>
                <Link
                  className="text-reset"
                  to="/consorcios"
                  rel="noreferrer noopener"
                  target={"_blank"}
                >
                  Consórcios
                </Link>
              </p>

              <p>
                <Link
                  className="text-reset"
                  to="/portabilidadeemprestimo"
                  rel="noreferrer noopener"
                  target={"_blank"}
                >
                  Portabilidade de Empréstimo
                </Link>
              </p>
              <p className="text-reset" style={{ color: "black" }}>
                <a style={{ color: "black" }} href="https://bolsafamilia.grupomaisvalor.com.br/" target="blank">
                  Bolsa Família
                </a>
              </p>

              <p className="text-reset" style={{ color: "black" }}>
                <a style={{ color: "black" }} href="https://servidorpublico.grupomaisvalor.com.br/" target="blank">
                  Sevidor Público
                </a>
              </p>
              <p>
                <Link
                  className="text-reset"
                  to="/cartaobeneficio"
                  rel="noreferrer noopener"
                  target={"_blank"}
                >
                  Cartão Benefício
                </Link>
              </p>
              <p>
                <Link
                  className="text-reset"
                  to="/creditopessoal"
                  rel="noreferrer noopener"
                  target={"_blank"}
                >
                  Crédito Pessoal
                </Link>
              </p>
            </div>

            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto">
              <span className="text-uppercase fw-bold mb-4">Informações</span>
              <p className="mt-3">
                <a
                  href="https://carreirasmaisvalor.solides.jobs/"
                  target="_blank"
                  className="text-reset"
                  rel="noreferrer noopener"
                >
                  Trabalhe Conosco
                </a>
              </p>

              <p>
                <Link
                  to="/sobrenos"
                  target={"_blank"}
                  rel="noreferrer noopener"
                  className="text-reset"
                >
                  Sobre Nós
                </Link>
              </p>

              <p>
                <a
                  href="https://www.contatoseguro.com.br/grupomaisvalor"
                  target={"_blank"}
                  rel="noreferrer noopener"
                  className="text-reset"
                >
                  Canal de Ética
                </a>
              </p>

              <p>
                <Link
                  to="/politicaprivacidade"
                  target={"_blank"}
                  className="text-reset"
                  rel="noreferrer noopener"
                >
                  Política de Privacidade
                </Link>
              </p>

              <p>
                <Link
                  to="/termosdeuso"
                  target={"_blank"}
                  className="text-reset"
                  rel="noreferrer noopener"
                >
                  Termos de Uso
                </Link>
              </p>
            </div>

            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <span className="text-uppercase fw-bold mb-4">Fale com a mais valor</span>
              <p className="mt-3">
                <i className="fas fa-home me-3"></i>Rua Quinze de Novembro, 184
                <br />
                13° andar - Centro
                <br />
                São Paulo / SP
              </p>
              <p>
                <i className="fas fa-envelope me-3"></i>{" "}
                sac@grupomaisvalor.com.br
              </p>
              <p>
                <i className="fas fa-phone me-3"></i>TEL - (11) 3467-0070
              </p>
              <p>
                <i className="fas fa-phone me-3"></i>SAC - 0800-042-0710
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        © 2023 - Aqui + Valor Negocio Promocoes e Intermediacoes LTDA / CNPJ/MF
        n° 15.359.515/0001-50
      </div>
    </footer>
  );
};
export default Footer;

function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../assets/img/", false, /\.(png|jpe?g|svg)$/)
);
